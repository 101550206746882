const NAV_ITEMS = [
  "home",
  "info-management",
  "contact-book",
  "storage-management",
  "external-management",
  "system-setting",
]
const CONTRACT_NAV_ITEMS = [
  "home",
  "contract-create",
  "contract-view",
  "submit-stage-update",
  "view-stage-update",
]
const URL_SCAN = "https://confluxscan.io"
const CHINESE_NUM = [
  "零",
  "一",
  "二",
  "三",
  "四",
  "五",
  "六",
  "七",
  "八",
  "九",
  "十",
]
const ENGLISH_NUM = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]
export { NAV_ITEMS, CONTRACT_NAV_ITEMS, URL_SCAN, CHINESE_NUM, ENGLISH_NUM }
